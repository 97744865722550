import React from 'react';

import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import fp from 'lodash/fp';
import * as yup from 'yup';

import {Button} from '@material-ui/core';

const INITIALVALUES = {
    name: '',
    email: '',
    password: '',
    password2: '',
};

const validationSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().lowercase().required(),
    password: yup.string().min(6).required(),
    password2: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords don't match"),
});

export default function PasswordRegistrationForm({
    submitLabel,
    onSubmit,
    initialValues = INITIALVALUES,
}) {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize
        >
            <Form>
                <Field
                    component={TextField}
                    label='Name'
                    name='name'
                    fullWidth
                />
                <Field
                    component={TextField}
                    label='Email'
                    name='email'
                    fullWidth
                    disabled={fp.get('email', initialValues) !== ''}
                />
                <Field
                    component={TextField}
                    type='password'
                    label='Password'
                    name='password'
                    fullWidth
                />
                <Field
                    component={TextField}
                    type='password'
                    label='Password (again)'
                    name='password2'
                    fullWidth
                />
                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{marginTop: '8px'}}
                >
                    {submitLabel}
                </Button>
            </Form>
        </Formik>
    );
}
