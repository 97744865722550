import fp from 'lodash/fp';
import * as dfp from 'date-fns/fp';
import {useCallback, useEffect, useState} from 'react';
import {createSelector} from '@reduxjs/toolkit';
import {useSelector} from 'react-redux';

import {ducks, useApi} from '@arborian/narrf';
import {useFormController as useSharedFormController} from '@arborian/ui-shared';

import {useCurrentUser} from 'ccm/dataSource/misc';
import {useDialog} from 'ccm/components/dialogs';
import OrdersToolDialog from '../ticket/OrdersToolDialog';

window.dfp = dfp;

export function useFormController({ticket, ticketActions, ...methods}) {
    const api = useApi();
    const {user, signatureBlob} = useUserSignature(api);
    const form = useForm(ticket);
    const ordersDialog = useDialog(OrdersToolDialog);

    const handleSavePage = useCallback(
        async (blob, fields, page) => {
            let body = new FormData();
            body.append('file', blob);
            body.append(
                'data',
                JSON.stringify({
                    data: fp.set('attributes.fields', fields, page),
                }),
            );
            await api.fetchJsonApi(page.links.content, {
                method: 'PUT',
                body,
            });
        },
        [api],
    );
    const ordersValue = fp.get('attributes.data.orders', ticket);
    const handleEditOrders = useCallback(
        async ticketOrders => {
            const {action, text} = await ordersDialog(ticketOrders);
            if (action !== 'submit') return undefined;
            console.log('Dialog returned', {action, text});
            return text;
        },
        [ordersDialog],
    );
    const handleSaveOrders = useCallback(
        newOrders => ticketActions.patch(ticket, {data: {orders: newOrders}}),
        [ticket, ticketActions],
    );
    const hookProps = {
        user,
        signatureBlob,
        ordersValue,
        form,
        onSavePage: handleSavePage,
        onSaveOrders: handleSaveOrders,
        onEditOrders: handleEditOrders,
        ...methods,
    };
    return useSharedFormController(hookProps);
}

const selectFormPages = createSelector(
    (_, form) => form,
    ducks.jsonapi.selectObject(['SignatureFormPage']),
    (form, allPages) => {
        const linkage = form ? form.relationships.pages.data : [];
        return linkage.map(d => allPages[d.id]);
    },
);

const selectForm = createSelector(
    (_, ticket) => ticket,
    ducks.jsonapi.selectObject(['SignatureForm']),
    (ticket, forms) => forms[ticket.relationships.signature_form.data.id],
);

function useForm(ticket) {
    return useSelector(state => selectForm(state, ticket));
}

export function useFormPages(formController) {
    return useSelector(state => selectFormPages(state, formController.form));
}

function useUserSignature(api) {
    const [signatureBlob, setSignatureBlob] = useState();
    const user = useCurrentUser();

    useEffect(() => {
        if (!user) return;
        const blobHref = fp.get(
            'relationships.signature_blob_processed.links.related',
            user,
        );
        if (!blobHref) return;
        api.fetchJsonApi(blobHref).then(resp => {
            setSignatureBlob(resp.data);
        });
    }, [user, api]);
    return {user, signatureBlob};
}
