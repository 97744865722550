import React, {useState} from 'react';
import fp from 'lodash/fp';

import {Menu, MenuItem, Button, ButtonGroup} from '@material-ui/core';
import {ArrowDropDown} from '@material-ui/icons';
import {useDialog} from 'ccm/components/dialogs';

import SignatureRequestEditDialog from 'ccm/components/dialogs/SignatureRequestEditDialog';

export default function SignatureRequestButton({ticket, actions}) {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const signatureForm = fp.get('relationships.signature_form', ticket);
    const signDialog = useDialog(SignatureRequestEditDialog);

    const handleEditRequest = async ev => {
        await signDialog(ticket, actions);
    };
    const handleCreateRequest = async ev => {
        // The ticket gets modified by the signature request creation, so
        // we must re-fetch it
        const resp = await actions.create_signature_request(ticket);
        await signDialog(resp.data, actions);
    };
    const handleDeleteRequest = async ev => {
        setMenuAnchor(null);
        return actions.delete_signature_request(ticket);
    };

    return signatureForm?.data ? (
        <>
            <ButtonGroup variant='contained' color='primary'>
                <Button
                    onClick={handleEditRequest}
                    color='default'
                    variant='contained'
                >
                    Edit Signature Request
                </Button>
                <Button
                    color='default'
                    size='small'
                    onClick={ev => setMenuAnchor(ev.currentTarget)}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>

            <Menu
                open={!!menuAnchor}
                anchorEl={menuAnchor}
                onClose={() => setMenuAnchor()}
            >
                <MenuItem onClick={handleDeleteRequest}>
                    Delete Signature Request
                </MenuItem>
            </Menu>
        </>
    ) : (
        <Button
            onClick={handleCreateRequest}
            color='default'
            variant='contained'
        >
            Create Signature Request
        </Button>
    );
}
