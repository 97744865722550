import {useCallback, useEffect} from 'react';
import fp from 'lodash/fp';

import {hooks} from '@arborian/narrf';

const {useDataSource, useTableDataSource} = hooks;

const DEFAULT_FETCH_OPTIONS = {
    page: {
        number: 0,
        size: 20,
    },
}; // cannot be null (looks for sort field)

// Data Source for list fields within other resources
// (hopefully allows working with dt2, etc.)
const DEFAULT_OPTIONS = {
    reversed: false,
};
export default function useListField(
    value,
    onChange,
    options = DEFAULT_OPTIONS,
) {
    const fetchItems = useCallback(
        async fetchOptions => {
            if (fp.isEmpty(value)) {
                return {rows: [], count: 0};
            }
            if (options.reversed) {
                return {rows: fp.reverse(value), count: value.length};
            } else {
                return {rows: value, count: value.length};
            }
        },
        [value, options.reversed],
    );
    const ds = useTableDataSource(
        useDataSource({
            data: fetchItems,
            fetchOptions: DEFAULT_FETCH_OPTIONS,
        }),
    );
    const handleAdd = useCallback(
        async row => {
            onChange && onChange([...value, row.data]);
        },
        [value, onChange],
    );

    const handleUpdate = useCallback(
        async row => {
            const newValue = fp.map(it => {
                if (it.id === row.data.id) {
                    return row.data;
                } else {
                    return it;
                }
            }, value);
            onChange && onChange(newValue);
        },
        [value, onChange],
    );

    const handleDelete = useCallback(
        async row => {
            const newValue = fp.remove(it => it.id === row.data.id, value);
            onChange && onChange(newValue);
        },
        [value, onChange],
    );

    useEffect(() => {
        ds.fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    return {...ds, handleAdd, handleUpdate, handleDelete};
}
