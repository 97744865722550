import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import fp from 'lodash/fp';

import {makeStyles} from '@material-ui/core';

import {components, useApi, ducks} from '@arborian/narrf';

import {useDialog} from 'ccm/components/dialogs';
import {useListField} from 'ccm/dataSource';

import {
    selectShiftIndex,
    selectShiftAssignmentIndex,
    selectRulesetFacilities,
} from './routingSelectors';

import ShiftDialog from './ShiftDialog';
import ShiftAssignmentsDialog from './ShiftAssignmentsDialog';

const {dt2} = components;

const useStyles = makeStyles(theme => ({
    tableTitle: {
        '& h6': {
            margin: 0,
        },
    },
    thin: {
        fontWeight: 100,
    },
}));

function FormattedShiftAssignment({facilityId, shiftId}) {
    const classes = useStyles();
    const shiftAssignmentIndex = useSelector(selectShiftAssignmentIndex);
    const assignment = fp.get([facilityId, shiftId], shiftAssignmentIndex);
    // console.log({shiftAssignmentIndex, facilityId, shiftId, assignment});
    const owner = useSelector(
        ducks.jsonapi.selectObject([
            'UserSummary',
            fp.get('relationships.owner.data.id', assignment),
        ]),
    );
    const provider = useSelector(
        ducks.jsonapi.selectObject([
            'UserSummary',
            fp.get('relationships.provider.data.id', assignment),
        ]),
    );

    return (
        <>
            <span className={classes.thin}>Owner:</span>{' '}
            {fp.get('attributes.name', owner)}
            <br />
            <span className={classes.thin}>Provider:</span>{' '}
            {fp.get('attributes.name', provider)}
        </>
    );
}

function FormattedFallbackAssignment({facilityId, fallbackIndex}) {
    const classes = useStyles();
    const assignment = fallbackIndex[facilityId];
    const owner = useSelector(
        ducks.jsonapi.selectObject([
            'UserSummary',
            fp.get('owner_id', assignment),
        ]),
    );
    const provider = useSelector(
        ducks.jsonapi.selectObject([
            'UserSummary',
            fp.get('provider_id', assignment),
        ]),
    );

    return (
        <>
            <span className={classes.thin}>Owner:</span>{' '}
            {fp.get('attributes.name', owner)}
            <br />
            <span className={classes.thin}>Provider:</span>{' '}
            {fp.get('attributes.name', provider)}
        </>
    );
}

export default function AssignmentsTable({ruleset, rule}) {
    const api = useApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const facilitySelector = useCallback(selectRulesetFacilities(ruleset), [
        ruleset,
    ]);
    const facilities = useSelector(facilitySelector);
    const shiftDialog = useDialog(ShiftDialog);
    const shiftAssignmentDialog = useDialog(ShiftAssignmentsDialog);
    const fallbackIndex = useMemo(
        () =>
            fp.pipe([
                fp.get('attributes.fallbacks'),
                fp.map(fb => [fb.facility_id, fb]),
                fp.fromPairs,
            ])(rule),
        [rule],
    );

    const shiftIndex = useSelector(selectShiftIndex);
    const shifts = fp.get(rule.id, shiftIndex);
    const visibleFacilities = useMemo(() => {
        const fake = {
            id: null,
            attributes: {
                name: ' -- no facility -- ',
            },
        };
        return [fake, ...facilities];
    }, [facilities]);
    const editShifts = useCallback(async () => {
        await shiftDialog(rule.id);
        await api.fetchJsonApi(rule.links.self, {include: ['shifts']});
    }, [shiftDialog, rule, api]);

    const handleEditAssignments = useCallback(
        async (ev, rows) => {
            await shiftAssignmentDialog({
                rule,
                facilities: fp.map('data', rows),
            });
        },
        [rule, shiftAssignmentDialog],
    );

    const ds = useListField(visibleFacilities);

    return (
        <dt2.DataTable
            id='assignments-table'
            key={rule.id}
            title={`${rule.attributes.name} Assignments`}
            dataSource={ds}
            options={{
                selection: true,
            }}
        >
            <dt2.Column
                key='facility'
                title='Facility'
                field='attributes.name'
            />
            <dt2.Column
                key='fallback'
                title='Fallback'
                field=''
                render={row => (
                    <FormattedFallbackAssignment
                        facilityId={row.id}
                        fallbackIndex={fallbackIndex}
                    />
                )}
            />
            {fp.map(
                shift => (
                    <dt2.Column
                        key={shift.id}
                        title={fp.get('attributes.name', shift)}
                        filtering={false}
                        sorting={false}
                        // render={row => formatAssignments(row, shift)}
                        render={row => (
                            <FormattedShiftAssignment
                                facilityId={row.id}
                                shiftId={shift.id}
                            />
                        )}
                    />
                ),
                shifts,
            )}
            <dt2.Action
                id='schedule-button'
                free
                name='schedule'
                icon='schedule'
                tooltip='Edit Shifts'
                onClick={editShifts}
            />
            <dt2.Action
                id='edit-button'
                name='edit'
                icon='edit'
                onClick={handleEditAssignments}
            />
        </dt2.DataTable>
    );
}
