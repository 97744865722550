import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

import SignatureForm, {useFormController} from 'ccm/components/SignatureForm';

function Content({title, ticket, ticketActions, onResolve}) {
    const formController = useFormController({ticket, ticketActions});

    const handleSave = async () => {
        await formController.save();
        onResolve(null);
    };

    const handleCancel = async () => {
        onResolve(null);
    };

    return (
        <>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <SignatureForm formController={formController} isEditing />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                    onClick={handleSave}
                    variant='contained'
                    color='primary'
                >
                    Save
                </Button>
            </DialogActions>
        </>
    );
}

export default function SignatureRequestEditDialog({dialogRef}) {
    const [resolve, setResolve] = useState(null);
    const [ticket, setTicket] = useState(null);
    const [ticketActions, setTicketActions] = useState(null);
    const [isEditing, setIsEditing] = useState(null);
    const [title, setTitle] = useState('Sign Ticket?');

    const handleResolve = resolution => {
        resolve(resolution);
        setResolve(null);
        setTicket(null);
        setTicketActions(null);
    };

    dialogRef.current = (ticket, tktActions, isEditing) =>
        new Promise((resolve, reject) => {
            setIsEditing(isEditing);
            setTicket(ticket);
            setTitle(isEditing ? 'Signature Request' : 'Sign Ticket?');
            setTicketActions(tktActions);
            setResolve(() => resolve);
        });

    console.log('SignatureRequestEditDialog', {resolve, ticket});

    return (
        <Dialog
            open={!!resolve}
            onClose={() => handleResolve(null)}
            scroll='paper'
            fullScreen
            disableEnforceFocus={true}
        >
            {ticket && (
                <Content
                    title={title}
                    ticket={ticket}
                    ticketActions={ticketActions}
                    isEditing={isEditing}
                    onResolve={handleResolve}
                />
            )}
        </Dialog>
    );
}
