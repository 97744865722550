var D = Object.defineProperty;
var S = (r, t, e) => t in r ? D(r, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : r[t] = e;
var l = (r, t, e) => S(r, typeof t != "symbol" ? t + "" : t, e);
import o from "lodash/fp";
import { useMemo as P } from "react";
import * as N from "date-fns/fp";
import { fabric as f } from "fabric";
class L {
  // Thanks to https://dev.to/0916dhkim/simple-typescript-mutex-implementation-5544
  constructor() {
    this._queue = [], this._isLocked = !1;
  }
  acquire() {
    return new Promise((t) => {
      this._queue.push({ resolve: t }), this._dispatch();
    });
  }
  async runExclusive(t) {
    const e = await this.acquire();
    try {
      return await t();
    } finally {
      e();
    }
  }
  _dispatch() {
    if (this._isLocked) return;
    const t = this._queue.shift();
    t && (this._isLocked = !0, t.resolve(() => {
      this._isLocked = !1, this._dispatch();
    }));
  }
}
const g = class g {
  constructor(t) {
    l(this, "log", (...t) => console.log(this._prefix, ...t));
    const e = g.nonce(t);
    this._prefix = `${t}[${e}]`;
  }
  static nonce(t) {
    const e = g._next[t] || 1;
    return g._next[t] = e + 1, e;
  }
  sub(t) {
    return new g(`${this._prefix}.${t}`);
  }
};
l(g, "_next", {});
let w = g;
const R = (r, t) => new Promise((e, s) => {
  r.setBackgroundImage(
    t,
    (i, a) => a ? s(i) : e(i),
    { crossOrigin: "Anonymous" }
  );
}), V = (r, t) => {
  const e = t.width, s = t.height, i = r.getWidth();
  if (e > i) {
    let a = i / e;
    r.setZoom(a);
  }
  r.setHeight(r.getZoom() * s), r.calcOffset();
};
function A(r) {
  return r.setWidth(r.backgroundImage.width), r.setHeight(r.backgroundImage.height), r.setViewportTransform([1, 0, 0, 1, 0, 0]), r.calcOffset(), r.discardActiveObject(), r.renderAll(), new Promise((t) => {
    r.toCanvasElement().toBlob(t, "image/png");
  });
}
function X(r, t) {
  let e = !r, s = null;
  for (const a of t) {
    const n = a.getSortedObjects();
    for (var i of n) {
      if (s || (s = {
        pageNumber: a.pageNumber,
        element: i
      }), e)
        return {
          pageNumber: a.pageNumber,
          element: i
        };
      r.pageNumber === a.pageNumber && r.element === i && (e = !0);
    }
  }
  return s;
}
const I = N.format("p O P");
class T {
  constructor(t) {
    l(this, "countIncomplete", () => {
      var e, s;
      const t = o.sum(
        o.map((i) => i.numIncomplete, this._pageControllers)
      );
      (s = (e = this.options).setNumIncomplete) == null || s.call(e, t);
    });
    l(this, "handleSavePage", (t, e, s) => {
      const { onSavePage: i } = this.options;
      if (i)
        return i(t, e, s);
    });
    l(this, "handleEditOrders", async () => {
      const { onEditOrders: t } = this.options;
      if (t) {
        const e = await t(this.ordersValue);
        o.isUndefined(e) || (this._ordersValue = e);
      }
      return this._ordersValue;
    });
    l(this, "save", async () => {
      this._log.log("save"), await Promise.all(o.map((e) => e.saveCanvas(), this._pageControllers));
      const { onSaveOrders: t } = this.options;
      t && !o.isUndefined(this._ordersValue) && this._ordersValue !== this.options.ordersValue && await t(this._ordersValue);
    });
    l(this, "registerPageController", (t) => {
      this._log.log("registerPageController"), this._pageControllers[t.pageNumber] = t;
    });
    this._formId = t, this._log = new w(`FormController(${t})`), this._log.log("new"), this._pageControllers = {}, this.current = { pageNumber: 0, element: null }, this._ordersValue = void 0, this.form = null, this.options = {};
  }
  get signatureValue() {
    const { user: t, signatureBlob: e } = this.options, s = o.get(
      "relationships.content.links.related",
      e
    ), i = o.get("attributes.label", t), a = I(/* @__PURE__ */ new Date()), n = { name: i, url: s, timestamp: a };
    return this._log.log("signatureValue", {
      signatureBlob: e,
      result: n
    }), JSON.stringify(n);
  }
  get ordersValue() {
    return o.isUndefined(this._ordersValue) ? this.options.ordersValue : this._ordersValue;
  }
  handleClickNext(t) {
    t || (t = this.current);
    const e = o.pipe([o.values, o.sortBy("pageNumber")])(
      this._pageControllers
    ), s = X(t, e);
    if (!s) return;
    const i = e[s.pageNumber];
    if (i) {
      for (var a = 0; a < e.length; a++)
        a !== s.pageNumber && e[a].clearActiveObjects();
      i.canvas.setActiveObject(s.element), i.canvas.requestRenderAll(), this.setCurrent(s.pageNumber, s.element);
    }
  }
  setCurrent(t, e) {
    var c, h, p, u;
    if (!e) {
      (h = (c = this.options).setProgressState) == null || h.call(c, null);
      return;
    }
    this.current = { pageNumber: t, element: e };
    const s = e.field.progressLabel;
    let i = 0;
    for (var a = 0; a < t; a++)
      i += this._pageControllers[a].canvasHeight;
    const n = i + this._pageControllers[t].scale(e.top);
    (u = (p = this.options).setProgressState) == null || u.call(p, {
      style: { top: n },
      label: s
    });
  }
}
const m = class m {
  static fromFieldData(t, e) {
    const s = m.TOOLS[t.type];
    return new s(o.cloneDeep(t), e);
  }
  constructor(t, e) {
    this.data = t, this.pageController = e.pageController;
  }
  get progressLabel() {
    return "Next";
  }
  draw() {
  }
  click() {
  }
  get filled() {
    return this.data.filled;
  }
  set filled(t) {
    this.data.filled = t, this.pageController.formController.countIncomplete();
  }
  _postClick(t, e, s) {
    return this.data.filled ? this.pageController.handleClickNext(e) : (this.pageController.setCurrent(e), s.setActiveObject(e)), e;
  }
  set(t, e) {
    t.set({ field: this, ...e }), this.data.field_data.elProps = o.merge(
      this.data.field_data.elProps,
      e
    );
  }
  scaleElement(t, e, { useMax: s = !0 }) {
    if (!(t != null && t.width)) return e;
    const i = t.width * t.scaleX / e.width, a = t.height * t.scaleY / e.height, n = s ? Math.max(i, a) : Math.min(i, a);
    return this.set(e, { scaleX: n, scaleY: n }), e;
  }
  toJSON() {
    return {
      id: this.data.id,
      value: this.data.value,
      type: this.data.type,
      filled: this.data.filled
    };
  }
};
l(m, "UNFILLED_BACKGROUND", "lightyellow"), l(m, "TOOLS", {});
let d = m;
const C = class C extends d {
  constructor() {
    super(...arguments);
    l(this, "click", async (e, s, i) => {
      let a = null;
      return this.filled ? (this.filled = !1, this.data.value = null) : (this.filled = !0, this.data.value = this.pageController.signatureValue), a = await this.draw(), a.oldElProps = o.pick(
        ["top", "left", "scaleX", "scaleY", "angle"],
        s
      ), a.set(o.pick(["top", "left", "angle"], s)), this.scaleElement(s, a, { useMax: this.filled }), i.add(a), i.remove(s), this._postClick(e, a, i);
    });
  }
  get progressLabel() {
    return this.filled ? "Next" : "Sign";
  }
  toJSON(e) {
    let s = super.toJSON(e);
    return s.field_data = {
      elProps: o.pick(["top", "left", "scaleX", "scaleY", "angle"], e),
      oldElProps: e.oldElProps || {}
    }, s;
  }
  draw() {
    return new Promise((e) => {
      if (this.filled) {
        var s, i;
        try {
          const a = JSON.parse(this.data.value);
          s = a.url, i = `Electronically Signed by ${a.name}
${a.timestamp}`;
        } catch (a) {
          s = this.data.value, i = "", console.log("Error parsing signature data", a);
        }
        f.Image.fromURL(
          s,
          (a) => {
            const n = a.top + a.getScaledHeight(), c = new f.Textbox(i, {
              field: this,
              width: a.width,
              top: n,
              left: a.left
            }), h = new f.Group(
              [a, c],
              { field: this }
            );
            o.isEmpty(this.data.field_data) ? (console.log("Setting group props", { group: h }), this.set(h, {
              left: this.data.coords.left,
              top: this.data.coords.top,
              scaleX: this.data.coords.width / h.width,
              scaleY: this.data.coords.height / h.height
            })) : this.set(h, this.data.field_data.elProps), h.setControlsVisibility({
              mt: !1,
              mb: !1,
              ml: !1,
              mr: !1
            }), e(h);
          },
          { crossOrigin: "Anonymous" }
        );
      } else
        f.Image.fromURL(
          C.imgSrc,
          (a) => {
            a.setControlsVisibility({
              mt: !1,
              mb: !1,
              ml: !1,
              mr: !1
            }), o.isEmpty(this.data.field_data) ? this.set(a, {
              backgroundColor: d.UNFILLED_BACKGROUND,
              left: this.data.coords.left,
              top: this.data.coords.top,
              scaleX: this.data.coords.width / a.width,
              scaleY: this.data.coords.height / a.height
            }) : this.set(a, {
              backgroundColor: d.UNFILLED_BACKGROUND,
              ...this.data.field_data.elProps
            }), e(a);
          },
          { crossOrigin: "Anonymous" }
        );
    });
  }
};
l(C, "imgSrc", "/img/signatureBox.png"), l(C, "initialProps", {
  width: 350,
  height: 150,
  scaleX: 1,
  scaleY: 1
});
let v = C;
const U = N.format("P"), b = class b extends d {
  get progressLabel() {
    return this.filled ? "Next" : "Enter date";
  }
  toJSON(t) {
    let e = super.toJSON(t);
    const s = o.pick(
      ["top", "left", "scaleX", "scaleY", "angle"],
      t
    );
    return e.field_data = {
      elProps: s,
      oldElProps: t.oldElProps || s
    }, e;
  }
  draw() {
    return new Promise((t) => {
      if (this.filled) {
        var e = new f.Textbox(
          this.data.value || "NO DATE ENTERED",
          { fontSize: 40, editable: !1 }
        );
        e.setControlsVisibility({
          mt: !1,
          mb: !1,
          ml: !1,
          mr: !1
        }), o.isEmpty(this.data.field_data) ? this.set(e, {
          backgroundColor: void 0,
          left: this.data.coords.left,
          top: this.data.coords.top,
          scaleX: this.data.coords.width / e.width,
          scaleY: this.data.coords.height / e.height
        }) : this.set(e, {
          ...this.data.field_data.elProps,
          backgroundColor: void 0
        }), t(e);
      } else
        f.Image.fromURL(
          b.imgSrc,
          (s) => {
            s.setControlsVisibility({
              mt: !1,
              mb: !1,
              ml: !1,
              mr: !1
            }), o.isEmpty(this.data.field_data) ? this.set(s, {
              backgroundColor: d.UNFILLED_BACKGROUND,
              left: this.data.coords.left,
              top: this.data.coords.top,
              scaleX: this.data.coords.width / s.width,
              scaleY: this.data.coords.height / s.height
            }) : this.set(s, {
              ...this.data.field_data.elProps,
              backgroundColor: d.UNFILLED_BACKGROUND
            }), t(s);
          },
          { crossOrigin: "Anonymous" }
        );
    });
  }
  async click(t, e, s) {
    this.filled ? (this.filled = !1, this.data.value = null) : (this.filled = !0, this.data.value = U(/* @__PURE__ */ new Date()));
    const i = await this.draw();
    return i.oldElProps = o.pick(
      ["top", "left", "scaleX", "scaleY", "angle"],
      e
    ), i.set(o.pick(["top", "left", "angle"], e)), this.scaleElement(e, i, { useMax: this.filled }), s.add(i), s.remove(e), this._postClick(e, i, s);
  }
};
l(b, "imgSrc", "/img/dateBox.png");
let E = b;
const _ = class _ extends d {
  constructor(t, e) {
    super(
      o.merge(
        {
          value: "ENTER TEXT"
        },
        t
      ),
      e
    );
  }
  get progressLabel() {
    return this.filled ? "Next" : "Enter text";
  }
  toJSON(t) {
    let e = super.toJSON(t);
    const s = o.pick(
      ["top", "left", "width", "scaleX", "scaleY", "angle"],
      t
    );
    return e.field_data = {
      elProps: s,
      oldElProps: t.oldElProps || s
    }, o.set("value", t.text, e);
  }
  draw() {
    return new Promise((t) => {
      if (this.filled) {
        var e = new f.Textbox(
          this.data.value || "NO TEXT ENTERED",
          { fontSize: 40, editable: !0 }
        );
        e.setControlsVisibility({
          mt: !1,
          mb: !1
        }), o.isEmpty(this.data.field_data) ? this.set(e, {
          left: this.data.coords.left,
          top: this.data.coords.top,
          width: this.data.coords.width
        }) : this.set(e, this.data.field_data.elProps), e.on("editing:exited", () => {
          this.handleEditingExited(e);
        }), t(e);
      } else
        f.Image.fromURL(
          _.imgSrc,
          (s) => {
            s.setControlsVisibility({
              mt: !1,
              mb: !1,
              ml: !1,
              mr: !1
            }), o.isEmpty(this.data.field_data) ? this.set(s, {
              backgroundColor: d.UNFILLED_BACKGROUND,
              left: this.data.coords.left,
              top: this.data.coords.top
            }) : this.set(s, {
              ...o.omit(
                ["width"],
                this.data.field_data.elProps
              ),
              backgroundColor: d.UNFILLED_BACKGROUND
            }), t(s);
          },
          { crossOrigin: "Anonymous" }
        );
    });
  }
  async click(t, e, s) {
    if (this.filled)
      return this.pageController.setCurrent(t.element), e;
    this.filled = !0;
    const i = await this.draw();
    return i.oldElProps = o.pick(
      ["top", "left", "width", "height", "scaleX", "scaleY", "angle"],
      e
    ), i.set(
      o.pick(["top", "left", "width", "scaleX", "scaleY", "angle"], e)
    ), s.add(i), s.remove(e), i.enterEditing(), i.selectAll(), this.pageController.setCurrent(i), s.setActiveObject(i), this.set(i, { backgroundColor: void 0 }), i;
  }
  async handleEditingExited(t) {
    if (t.text.trim() === "") {
      console.log("Empty text"), this.filled = !1, this.data.value = null;
      const e = await this.draw();
      console.log("Drew", e), e.set(
        o.pick(["top", "left", "scaleX", "scaleY", "angle"], t)
      ), this.pageController.canvas.add(e), this.pageController.canvas.remove(t);
    }
  }
};
l(_, "imgSrc", "/img/textBox.png");
let O = _;
const y = class y extends d {
  constructor(t, e) {
    super(t, e), this.pageController.ordersValue && (this.filled = !0);
  }
  get progressLabel() {
    return this.filled ? "Next" : "Enter orders";
  }
  toJSON(t) {
    let e = super.toJSON(t);
    const s = o.pick(
      ["top", "left", "width", "scaleX", "scaleY", "angle"],
      t
    );
    return e.field_data = {
      elProps: s,
      oldElProps: t.oldElProps || s
    }, o.set("value", t.text, e);
  }
  draw() {
    return new Promise((t) => {
      if (this.filled) {
        const s = this.pageController.ordersValue;
        var e = new f.Textbox(
          s || "NO ORDERS ENTERED",
          { fontSize: 40, editable: !1 }
        );
        e.setControlsVisibility({
          mt: !1,
          mb: !1
        }), o.isEmpty(this.data.field_data) ? this.set(e, {
          left: this.data.coords.left,
          top: this.data.coords.top,
          width: this.data.coords.width
        }) : this.set(e, this.data.field_data.elProps), t(e);
      } else
        f.Image.fromURL(
          y.imgSrc,
          (s) => {
            s.setControlsVisibility({
              mt: !1,
              mb: !1,
              ml: !1,
              mr: !1
            }), o.isEmpty(this.data.field_data) ? this.set(s, {
              backgroundColor: d.UNFILLED_BACKGROUND,
              left: this.data.coords.left,
              top: this.data.coords.top
            }) : this.set(s, {
              ...o.omit(
                ["width"],
                this.data.field_data.elProps
              ),
              backgroundColor: d.UNFILLED_BACKGROUND
            }), t(s);
          },
          { crossOrigin: "Anonymous" }
        );
    });
  }
  async click(t, e, s) {
    const i = await this.pageController.handleEditOrders();
    if (o.isUndefined(i)) return;
    this.filled = !o.isEmpty(i), this.data.value = i;
    const a = await this.draw();
    return a.oldElProps = o.pick(
      ["top", "left", "width", "height", "scaleX", "scaleY", "angle"],
      e
    ), a.set(
      o.pick(["top", "left", "width", "scaleX", "scaleY", "angle"], e)
    ), s.add(a), s.remove(e), this._postClick(t, a, s);
  }
};
l(y, "imgSrc", "/img/ordersBox.png");
let k = y;
class x extends d {
  get progressLabel() {
    return this.filled ? this.data.value === "true" ? "Un-check" : "Next" : "Check";
  }
  toJSON(t) {
    let e = super.toJSON(t), s = o.pick(["top", "left", "scaleX", "scaleY", "angle"], t);
    return e.field_data = {
      elProps: s,
      oldElProps: t.oldElProps || s
    }, e;
  }
  draw() {
    return new Promise((t) => {
      const e = new f.Rect({
        fill: null,
        stroke: "black",
        strokeWidth: 3,
        field: this,
        width: 50,
        height: 50
      });
      e.setControlsVisibility({
        mt: !1,
        mb: !1,
        ml: !1,
        mr: !1
      });
      const s = this.data.value === "true";
      if (this.filled && s) {
        const a = new f.Path(
          "M 125 150 L 150 175 L 200 125 L 150 175 z",
          {
            strokeWidth: 15,
            stroke: "black",
            top: e.top,
            left: e.left
          }
        );
        a.scaleToHeight(
          e.getScaledHeight() - 15 * e.scaleY,
          !0
        );
        const n = new f.Group([e, a], { field: this });
        o.isEmpty(this.data.field_data) ? this.set(n, {
          left: this.data.coords.left,
          top: this.data.coords.top,
          scaleX: this.data.coords.width / n.width,
          scaleY: this.data.coords.height / n.height
        }) : this.set(n, this.data.field_data.elProps), n.setControlsVisibility({
          mt: !1,
          mb: !1,
          ml: !1,
          mr: !1
        }), t(n);
      } else
        o.isEmpty(this.data.field_data) ? this.set(e, {
          left: this.data.coords.left,
          top: this.data.coords.top,
          scaleX: this.data.coords.width / e.width,
          scaleY: this.data.coords.height / e.height
        }) : this.set(e, this.data.field_data.elProps), this.set(e, {
          backgroundColor: this.filled ? void 0 : d.UNFILLED_BACKGROUND
        }), e.setControlsVisibility({
          mt: !1,
          mb: !1,
          ml: !1,
          mr: !1
        }), t(e);
    });
  }
  async click(t, e, s) {
    this.filled ? this.data.value === "true" ? this.data.value = "false" : (this.filled = !1, this.data.value = null) : (this.filled = !0, this.data.value = "true");
    const i = await this.draw();
    return i.set(o.pick(["top", "left", "angle", "scaleX", "scaleY"], e)), s.add(i), s.remove(e), this._postClick(t, i, s);
  }
}
l(x, "imgSrc", "/img/checkBox.png");
d.TOOLS = {
  SIGNATURE: v,
  DATE: E,
  TEXTBOX: O,
  ORDERSBOX: k,
  CHECKBOX: x
};
class B {
  constructor(t) {
    // TODO: is this dead code?
    l(this, "scale", (t) => {
      var s;
      return (((s = this.canvas) == null ? void 0 : s.getZoom()) || 1) * t;
    });
    l(this, "onReady", async (t) => {
      const e = this._log.sub("onReady");
      e.log("Enter onReady", {
        canvas: t,
        newCanvas: t !== this.canvas
      }), this.canvas = t, t && await this.initPage(), e.log("Leave onReady");
    });
    l(this, "initPage", async () => await this._initMutex.runExclusive(() => this._initPage()));
    l(this, "_initPage", async () => {
      const t = this._log.sub("initPage");
      t.log("begin");
      const e = this.canvas.getObjects(), s = o.get("attributes.imgSrc", this.page);
      if (!s || !this.canvas) {
        t.log("initPage failed b/c", { src: s, c: this.canvas });
        return;
      }
      const i = await R(this.canvas, s);
      t.log("background"), V(this.canvas, i);
      const a = o.map((c) => d.fromFieldData(c, {
        pageController: this
      }), this.page.attributes.fields);
      t.log("fields created", {
        objectsToRemove: e,
        fields: a
      });
      const n = await Promise.all(
        a.map(async (c) => await c.draw())
      );
      t.log("fields drawn"), this._removeEventHandlers(), n.forEach((c) => this.canvas.add(c)), this.canvas.remove(...e), this._addEventHandlers(), this.canvas.requestRenderAll(), this.formController.countIncomplete(), t.log("exit");
    });
    l(this, "_addEventHandlers", () => {
      this.canvas.on("object:added", () => {
        this._setDirty(!0), this.formController.countIncomplete();
      }), this.canvas.on("object:removed", () => {
        this._setDirty(!0), this.formController.countIncomplete();
      }), this.canvas.on("object:modified", () => {
        this._setDirty(!0);
      }), console.log("Added event handlers");
    });
    l(this, "_removeEventHandlers", () => {
      this.canvas.off(), console.log("Removed event handlers");
    });
    l(this, "saveCanvas", async () => {
      if (!this._dirty) {
        this._log.log("canvas: Not saving canvas, b/c it's not dirty", {
          state: this,
          dirty: this._dirty
        });
        return;
      }
      const t = this.canvas.getObjects().map((i) => i.field.toJSON(i)), e = await new Promise(
        (i) => this.canvas.clone(i, {
          enableRetinaScaling: !0
        })
      ), s = await A(e);
      this.formController.handleSavePage(s, t, this.page), this._setDirty(!1);
    });
    l(this, "getSortedObjects", () => this.canvas.getObjects().toSorted((s, i) => s.top === i.top ? s.left - i.left : s.top - i.top));
    l(this, "setCurrent", (t) => {
      this.formController.setCurrent(this.pageNumber, t);
    });
    l(this, "handleClickNext", (t) => this.formController.handleClickNext(this.pageNumber, t));
    l(this, "handleEditOrders", () => this.formController.handleEditOrders());
    l(this, "handleDrop", async (t) => {
      t.preventDefault();
      const { canvas: e } = this, s = JSON.parse(t.dataTransfer.getData("application/json")), { id: i, type: a, offset: n } = s, c = e.getPointer(t), h = {
        elProps: {
          left: c.x - n.x,
          top: c.y - n.y,
          scaleX: 1,
          scaleY: 1,
          angle: 0
          // h & w are added later
        },
        oldElProps: {}
      }, p = d.fromFieldData(
        { id: i, type: a, value: null, filled: !1, field_data: h },
        { pageController: this }
      ), u = await p.draw();
      return p.set(u, {
        left: c.x - u.width * n.x,
        top: c.y - u.height * n.y
      }), e.add(u), e.setActiveObject(u), u;
    });
    l(this, "handleClick", async (t) => {
      const e = this.canvas, s = e.findTarget(t);
      if (!s) return;
      await s.field.click(t, s, e), this.canvas.requestRenderAll();
    });
    l(this, "handleKeyDown", async (t) => {
      if (this._log.log("handleKeyDown", t.key, t.keyCode), t.key === "Backspace" || t.key === "Delete") {
        const e = this.canvas.getActiveObjects();
        this.canvas.remove(...e);
      }
    });
    l(this, "_setDirty", (t) => {
      this._dirty = t, this._log.log("_setDirty", {
        state: this,
        dirty: this._dirty
      });
    });
    this.pageNumber = t, this._log = new w(`PageController(${t})`), this._log.log("new"), this._dirty = !1, this._canvas = null, this._initMutex = new L(), this._formController = null, this.page = null, this.options = {};
  }
  get formController() {
    return this._formController;
  }
  set formController(t) {
    t !== this._formController && (this._formController = t, t.registerPageController(this));
  }
  get canvas() {
    return this._canvas;
  }
  set canvas(t) {
    this._canvas = t;
  }
  get canvasHeight() {
    var t;
    return ((t = this.canvas) == null ? void 0 : t.height) || 0;
  }
  get signatureValue() {
    return this.formController.signatureValue;
  }
  get ordersValue() {
    return this.formController.ordersValue;
  }
  get numIncomplete() {
    return o.sum(
      o.map((t) => t.field.filled ? 0 : 1, this.canvas.getObjects())
    );
  }
}
function H({
  form: r,
  ...t
  /*
  user,
  signatureBlob,
  ordersValue,
  onSavePage,
  onEditOrders,
  onSaveOrders,
  setProgressState,
  setNumIncomplete,
  */
}) {
  const e = r == null ? void 0 : r.id, s = P(() => new T(e), [e]);
  return s.form = r, s.options = t, s;
}
function K({
  page: r,
  pageNumber: t,
  formController: e,
  ...s
}) {
  const i = P(() => new B(t), [t]);
  return i.page = r, i.formController = e, i.options = s, i;
}
const q = o.pick(
  ["SIGNATURE", "DATE", "TEXTBOX", "ORDERSBOX", "CHECKBOX"],
  d.TOOLS
);
export {
  q as TOOLS,
  H as useFormController,
  K as usePageController
};
