import React, {useMemo} from 'react';
import {FabricJSCanvas} from 'fabricjs-react';

import {usePageController} from '@arborian/ui-shared';

// Class to help distinguish between click & drag
class ClickHelper {
    constructor(onClick, {delta = 6} = {}) {
        this._delta = delta;
        this._onClick = onClick;
        this._startX = this._startY = 0;
        this.handlers = {
            onMouseDown: this.handleMouseDown,
            onTouchStart: this.handleMouseDown,
            onMouseUp: this.handleMouseUp,
            onTouchEnd: this.handleMouseUp,
        };
    }

    handleMouseDown = ev => {
        this._startX = ev.pageX;
        this._startY = ev.pageY;
    };

    handleMouseUp = ev => {
        const diffX = Math.abs(ev.pageX - this._startX);
        const diffY = Math.abs(ev.pageY - this._startY);
        if (diffX < this._delta && diffY < this._delta) {
            this._onClick(ev);
        }
    };
}

export default function SignaturePage({
    formController,
    pageNumber,
    page,
    isEditing,
}) {
    const pageController = usePageController({
        formController,
        pageNumber,
        page,
    });
    const clickHelper = useMemo(
        () => new ClickHelper(pageController.handleClick),
        [pageController.handleClick],
    );

    const signHandlers = {
        ...clickHelper.handlers,
    };

    const designHandlers = {
        onDrop: pageController.handleDrop,
        onKeyDown: pageController.handleKeyDown,
    };
    const handlers = isEditing ? designHandlers : signHandlers;
    return (
        <div tabIndex={pageNumber} {...handlers}>
            <FabricJSCanvas onReady={pageController.onReady} />
        </div>
    );
}
