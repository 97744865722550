import React, {useRef, useEffect} from 'react';
import {makeStyles, Button} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        position: 'relative',
    },
    progressBtn: {
        backgroundColor: 'lightyellow',
        position: 'absolute',
        right: 0,
        '&:hover': {
            backgroundColor: 'yellow',
        },
        scrollMargin: theme.spacing(10),
    },
}));

const DEFAULT_PROGRESS_STATE = {
    label: 'Start',
    style: {},
};

export default function SigningProgress({formController, progressState}) {
    const classes = useStyles();
    const buttonRef = useRef();

    const {style, label} = progressState || DEFAULT_PROGRESS_STATE;
    useEffect(() => {
        buttonRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start',
        });
    }, [style, buttonRef]);

    return (
        <div className={classes.buttonContainer}>
            <Button
                onClick={ev => formController.handleClickNext()}
                className={classes.progressBtn}
                style={style}
                variant='contained'
                ref={buttonRef}
            >
                {label}
            </Button>
        </div>
    );
}
